import CustomerLogin from "../CustomerLogin"
import UpgradeLicense from "./UpgradeLicense"
import {
  Routes,
  Route
} from 'react-router-dom'
import { useState } from "react"

export default function UpgradePage() {
  const [ customerData, setCustomerData ] = useState({})

  return (
    <>
      <h1>Upgrade licentie</h1>      
      
      <Routes>
        <Route index="/" path="/" element={
          <CustomerLogin
            submitLabel={'Login'}
            setCustomerData={setCustomerData}
            redirectPath={'/licenses/upgrade/order'}
          />
        } />
        <Route path="order" element={
          <UpgradeLicense
            customerData={customerData}
          />
        } />
      </Routes>
    </>
  )
}
