import { Link } from "react-router-dom"

export default function Home() {
  return (
    <>
      <div className="row justify-content-between">
        <Link to="/licenses/order" className='col-3 border rounded-3 text-center p-4'>
          Bestel licentie
        </Link>
        <Link to="/licenses/upgrade" className='col-3 border rounded-3 text-center p-4'>
          Upgrade uw licentie
        </Link>
        <Link to="/credits" className="col-3 border rounded-3 text-center p-4">
          Bestel extra credits
        </Link>
      </div>
    </>
  )
}