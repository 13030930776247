// import Pages from './Pages'
import ClientSideNavigation from './ClientSideNavigation'
import Authorizer from './Authorizer';
// import {
  // BrowserRouter,
  // Routes,
  // Route } from 'react-router-dom'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { MsalProvider } from "@azure/msal-react";


function App({ pca }) {
  // return (
  //   <>
  //     {/* <BrowserRouter> */}
  //       <Routes>
  //         <Route path="/*" element={
  //           <Pages />
  //         } />
  //       </Routes>
  //     {/* </BrowserRouter> */}
  //     <ToastContainer />
  //   </>
  // )
  return (
    <ClientSideNavigation pca={pca}>
      <MsalProvider instance={pca}>
        <Authorizer />
        <ToastContainer />
      </MsalProvider>
    </ClientSideNavigation>
  )
}

export default App
