// import { useState, useEffect }  from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'
// import moment from 'moment'

SelectInput.propTypes = {
  handleChange: PropTypes.func,
  title: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  options: PropTypes.array,
  error: PropTypes.object,
  disabled: PropTypes.bool
}

SelectInput.defaultProps = {
  handleChange: (event) => console.info(`New value : ${event.target.value}`),
  title: null,
  name: null,
  value: null,
  options: null,
  error: {},
  disabled: false
}

export default function SelectInput({ title, name, value, options, handleChange, error, disabled }) {
  let classes = _.isEmpty(error) ? 'form-select' : 'form-select border-danger'
  const labelClassNames = _.isEmpty(error) ? '' : 'text-danger'

  return (
    <div className='my-2'>
      <label htmlFor={name} className={labelClassNames}>{title}</label>
      <br/>

      <select value={value.toString()} name={name} onChange={(event) => handleChange(event.target.value)} disabled={disabled} className={classes}>
        {options.map((option) => 
          (
            <option key={option.key} value={option.value}>{option.name}</option>
          )
        )}
      </select>
      {!_.isEmpty(error) && (
        <small className='text-danger'>
          {error.message}
        </small>
      )}
    </div>
  )
}
