import { toast } from 'react-toastify'
import countries from '../countries'
// import moment from 'moment'

export const pageLoader = (
  <div className='w-100 my-8 d-flex align-items-center justify-content-center'>
    <div className="spinner-border text-primary" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
)

export function truncateString(str, num) {
  if (str.length <= num) return str
  return str.slice(0, num) + '...'
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function submitSuccess(response) {
  return response.status === 200 && response.data.success
}

export function submitError(response) {
  // return response.status !== 200 && !response.data.success
  return response.status !== 200 && !response.ok
}

export function defaultToast(text) {
  return toast.info(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    theme: "light",
  });
}

export function successToast(text) {
  return toast.success(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    // draggable: true,
    progress: undefined,
    theme: "light",
  });
}

export function errorToast(text) {
  return toast.error(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    // draggable: true,
    progress: undefined,
    theme: "light",
  });
}

export function createSelectOptionsList(input, prompt) {
  let output = input.map((line) => {
    return { key: line.code, value: line.code, name: `${line.name} - ${line.code.toUpperCase()}` }
  })
  output.unshift({ key: '', value: '', name: prompt })
  return output
}

export function createCountrySelectOptionsList(input, prompt) {
  return createSelectOptionsList(input, prompt, 'country')
}

export function getCountryCodeByName(name) {
  const country = countries.find(country => country.name === name)
  if (country) return country.code

  return null
}

export function periodeTypes() {
  return [
    // { key: 'Month', name: 'Month', value: 'Month' },
    // { key: 'Year', name: 'Year', value: 'Year' }
    { key: 'Month', name: 'Maand', value: 'Month' },
    { key: 'Year', name: 'Jaar', value: 'Year' }
  ]
}

export function periodes(periodeType) {
  let periodes = []
  if (periodeType === 'Year') {
    for (let n = 1; n <= 5; n++) {
      periodes.push({ key: `${n}`, name: `${n}`, value: `${n}` })
    }
  } else if (periodeType === 'Month') {
    for (let n = 3; n <= 12; n++) {
      periodes.push({ key: `${n}`, name: `${n}`, value: `${n}` })
    }
  }
  return periodes
}