import axios from 'axios'
import { useState, useEffect, useCallback } from 'react'
import Loader from '../Views/Loader'
import { Link } from 'react-router-dom'
import OrderLicenseForm from './OrderLicenseForm'
import OrganisationForm from './OrganisationForm'
import { useParams } from 'react-router-dom'
import { submitError, errorToast, submitSuccess } from '../Helpers'
import { b2cPolicies, loginRequest } from "../../authConfig";
import callApi from '../../utils/ApiCall'
// import { useMsal } from '@azure/msal-react'
import { EventType, InteractionType, InteractionStatus, InteractionRequiredAuthError, ClientAuthError } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { ErrorComponent } from "../Views/Error";

export default function OrderLicense({ instance, authorized, activateAccount }) {
  const [ isLoading, setIsLoading ] = useState(true)
  const [ licenseTypes, setLicenseTypes ] = useState([])
  const [ orderStep, setOrderStep ] = useState(1)
  const [ orderData, setOrderData ] = useState({})
  const [ orderSuccess, setOrderSuccess ] = useState(false)
  const [ formErrorFields, setFormErrorFields ] = useState([])
  const { licenseType } = useParams()
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const getLicenseTypes = useCallback(async () => {
    // console.log('getLicenseTypes');
    await axios.get(`${process.env.REACT_APP_API_URL}/api/licenses/types`)
    .then(function (response) {
        // console.log(response);
        if (response.data) {
          setLicenseTypes(response.data.license_types)
        }
        // if (response) {
        //   setLicenseTypes(response)
        // }
      })
      .catch(function (error) {
        console.error(error);
      });
      setIsLoading(false)
  }, [])

  const calcLicense = useCallback(async (license_type, license_periode, periode) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/api/shop/licenses/${license_type}/${license_periode}/${periode}`)
      .then(function (response) {
        if (response.data) {
          return response.data
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  },[])
  

  const prevForm = useCallback(() => {
    setOrderStep(1)
  }, [])

  const handleLicenseSubmit = useCallback((data) => {
    setOrderData(data)
    setOrderStep(2)
  }, [] )

  const handleOrderSubmit = useCallback((data) => {
    // console.log(data);
    const updatedOrderData = {
      ...orderData,
      ...data
    }
    // console.log(updatedOrderData);
    setOrderData(updatedOrderData)
    axios.post(`${process.env.REACT_APP_API_URL}/api/shop/licenses/order`, updatedOrderData)
      .then(function (response) {
        // console.log(response);
        if (submitSuccess(response)) {
          // setOrderSuccess(true)
          setOrderStep(3)
          const { org_uuid, order_uuid, acc_uuid } = response.data
          localStorage.setItem('org_uuid', org_uuid)
          localStorage.setItem('order_data', JSON.stringify({
            order_uuid, firstname: updatedOrderData.firstname
          }))
          localStorage.setItem('acc_uuid', acc_uuid)
        }
      })
      .catch(function (error) {
        const response = error.response.data
        if (submitError(response)) {
          errorToast(response.message)
          setFormErrorFields(response.fields)
        }
      });
  }, [orderData] )

  useEffect(() => {
    const account = instance.getActiveAccount()
    // console.log(inProgress);
    // console.log(authorized);
    // console.log('activateAccount: ', account);

    async function activateAccount(oid, email, order_data, org_uuid, acc_uuid) {  
      await callApi(`/api/register/account`, 'PUT', {
        b2c_identifier: oid,
        email,
        org_uuid,
        order_uuid: order_data.order_uuid,
        uuid: acc_uuid
      })
        .then((response) => {
          // console.log(response);
          if (submitSuccess(response)) {
            setOrderData(order_data)
            setOrderSuccess(true)
            setTimeout(() => {
              localStorage.clear()
            }, 5000);
            // instance.logout()
          }
        })
        .catch(async (error) => {
          const errorResponse = await error.json()
          console.error(errorResponse);
          // setAcceptedError(errorResponse.error)
        });
    }

    const org_uuid = localStorage.getItem('org_uuid')
    const order_data = localStorage.getItem('order_data')
    const acc_uuid = localStorage.getItem('acc_uuid')

    // if (inProgress === 'none') return

    // console.log(licenseTypes.length);

    if (account) {
      if (order_data && org_uuid && acc_uuid) {
        activateAccount(account.idTokenClaims.oid, account.username, JSON.parse(order_data), org_uuid, acc_uuid)
      } else {
        localStorage.clear()
      }
      setIsLoading(false)
    } else {
      if (licenseTypes.length === 0) getLicenseTypes()
    }
  }, [instance, inProgress, authorized, licenseTypes, getLicenseTypes])

  function b2cSignUp() {
    const signUpRequest = {
      ...loginRequest,
      prompt: 'select_account',
      state: 'activate_account',
      authority: b2cPolicies.authorities.signUp.authority
    }

    instance.loginRedirect(signUpRequest)
  }

  // if (inProgress !== 'none') {
  //   return (
  //     <Loader />
  //   )
  if (isAuthenticated) {
    return (
      <MsalAuthenticationTemplate 
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest} 
        errorComponent={ErrorComponent} 
        loadingComponent={Loader}
      >
        {orderSuccess ? (
          <>
            <h2 className='text-success'>Bestelling voltooid!</h2>
            <div className='rounded-3 p-2 border'>
              Beste {orderData.firstname},<br/><br/>

              MemoMove dankt u voor uw bestelling.<br/><br/>
              <p>
                {/* Je zal een email van ons ontvangen met een beschrijving van uw bestelling en deze dient u te beantwoorden (verificatie van correcte inzender). */}
                Je zal een email van ons ontvangen met een beschrijving van uw bestelling.
                Tevens zullen wij u een handleiding toesturen en daarbij zullen wij ook een factuur meezenden.<br/>
                Je kan nu MemoMove beperkt gebruiken totdat de factuur betaald is (*), daarna kan je MemoMove gebruiken volgens de beschreven licentie-voorwaarden. Je zal ook een unieke klanten-id ontvangen die je daarna kan gebruiken voor eventuele extra bestellingen.
              </p>

              <small>
                (*) bij een 'gratis'-aanvraag wordt er geen factuur gestuurd.
              </small>
            </div>

            <Link to="https://memomove.eu" className="btn btn-primary my-4">Terug naar de website</Link>
            {/* <Link to={process.env.REACT_APP_ADMIN_URL} className="btn btn-primary my-4">Beheer uw organisatie</Link> */}
            {/* <Link to={process.env.REACT_APP_GO_URL} className="btn btn-primary my-4">Start MemoMove</Link> */}
          </>
        ) : (
          <Loader />
        )}
      </MsalAuthenticationTemplate>
    )
  } else if (inProgress === InteractionStatus.Startup || inProgress === InteractionStatus.HandleRedirect) {
    return (
      <Loader />
    )
  } else {
    return (
      <>
        <h1>Bestel licentie</h1>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <nav aria-label="breadcrumb">
              <ul className="list-unstyled w-100 d-flex pb-2 border-bottom mb-4">
                <li className={`d-flex align-items-center w-25 ${orderStep === 1 ? ' text-primary' : ''}`}>
                  <span className={`badge me-1 ${orderStep === 1 ? ' bg-primary' : 'bg-secondary'}`}>1</span>
                  Licentie type
                </li>
                <li className={`d-flex align-items-center w-25 ${orderStep === 2 ? ' text-primary' : ''}`}>
                  <span className={`badge me-1 ${orderStep === 2 ? ' bg-primary' : 'bg-secondary'}`}>2</span>
                  Organisatie gegevens
                </li>
                <li className={`d-flex align-items-center w-25 ${orderStep === 3 ? ' text-primary ' : ''}`}>
                  <span className={`badge bg-secondary me-1 ${orderStep === 3 ? ' bg-primary' : 'bg-secondary'}`}>3</span>
                  Login gegevens
                </li>
              </ul>
            </nav>

            {orderStep === 1 && (
              <OrderLicenseForm
                orderData={orderData}
                activeType={licenseType}
                licenseTypes={licenseTypes}
                formErrorFields={formErrorFields}
                submitLabel='Volgende'
                calcLicense={calcLicense}
                handleSubmit={handleLicenseSubmit}
              />
            )}
            
            {orderStep === 2 && (
              <OrganisationForm
                formErrorFields={formErrorFields}
                submitLabel='Bestel'
                handleSubmit={handleOrderSubmit}
                prevForm={prevForm}
              />
            )}

            {orderStep === 3 && (
              <div className='rounded-3 p-2 border'>
                <p>
                  Maak een login aan om MemoMove te gebruiken.
                </p>

                <button onClick={() => b2cSignUp()} className='mt-4 btn btn-primary'>
                  Maak een login
                </button>
              </div>
            )}
          </>
        )}
      </>
    )
  }
}
