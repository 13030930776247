import React from 'react'
// import moment from 'moment'
import { Link } from 'react-router-dom'
import TextInput from '../Form/TextInput'
import SelectInput from '../Form/SelectInput'
// import languages from '../languages'
import countries from '../../countries'
import { createCountrySelectOptionsList } from '../Helpers'

class OrganisationForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      formData: {
        firstname: '',
        lastname: '',
        name: '',
        vat_number: '',
        country_code: '',
        country_name: '',
        street1: '',
        street2: '',
        postalcode: '',
        city: '',
        email: ''
      },
      formErrors: [],
      submitLabel: '',
      loadingSubmit: false
    }
  }

  componentDidMount () {
    if (this.props.formData) {
      this.updateFormData(this.props.formData)
    }
  }

  updateFormData(data) {
    this.setState({ formData: data })
  }

  handleSubmit(e) {
    e.preventDefault()

    const organisationData = {
      ...this.state.formData
    }

    this.setState({loadingSubmit: true}, () => {
      this.props.handleSubmit(organisationData)
      this.setState({loadingSubmit: false})
    })
  }

  handleChange(fieldName, fieldValue) {
    let updatedFormData = this.state.formData
    updatedFormData[fieldName] = fieldValue
    this.updateFormData(updatedFormData)
  }

  handleAccountChange(fieldName, fieldValue) {
    let updatedFormData = this.state.formData
    updatedFormData['account'][fieldName] = fieldValue
    this.updateFormData(updatedFormData)
  }

  handleCountryChange(fieldValue) {
    const country = countries.find((country) => country.code === fieldValue )
    const newFormData = {
      ...this.state.formData,
      country_code: fieldValue,
      country_name: country ? country.name : ''
    }

    this.setState({ formData: newFormData })
  }

  countriesCodes() {
    countries.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
  
      // names must be equal
      return 0;
    });
    // return createCountrySelectOptionsList(countries, 'Select a country code')
    return createCountrySelectOptionsList(countries, 'Selecteer een landcode')
  }

  handleLicenseChange(fieldValue) {
    const newFormData = {
      ...this.state.formData,
      license_type: fieldValue
    }
    this.setState({ formData: newFormData })
  }

  errorMessage(errorFields, value) {
    return errorFields.find(error => error.field === value)
  }

  accountValue(accountData, field) {
    if (accountData) {
      return accountData[field] ? accountData[field] : ''
    }

    return ''
  }

  render () {
    const { submitLabel, formErrorFields } = this.props
    const { formData, loadingSubmit } = this.state

    return (
      <div className=''>
        <form className="mb-2" onSubmit={(e) => this.handleSubmit(e)}>
          <TextInput
            title={'Voornaam'}
            name={'firstname'}
            value={formData.firstname}
            type='text'
            focus={true}
            error={this.errorMessage(formErrorFields, 'firstname')}
            handleChange={(newValue) => this.handleChange('firstname', newValue)}
          />

          <TextInput
            title={'Achternaam'}
            name={'account_lastname'}
            value={formData.lastname}
            type='text'
            error={this.errorMessage(formErrorFields, 'lastname')}
            handleChange={(newValue) => this.handleChange('lastname', newValue)}
          />

          <TextInput
            title={'Organisatie naam'}
            name={'name'}
            value={formData.name}
            type='text'
            error={this.errorMessage(formErrorFields, 'name')}
            handleChange={(newValue) => this.handleChange('name', newValue)}
          />

          <TextInput
            title={'BTW-nummer'}
            name={'name'}
            value={formData.vat_number}
            type='text'
            error={this.errorMessage(formErrorFields, 'vat_number')}
            handleChange={(newValue) => this.handleChange('vat_number', newValue)}
          />

          <SelectInput
            title={'Landcode'}
            name={'country_code'}
            value={formData.country_code}
            options={this.countriesCodes()}
            error={this.errorMessage(formErrorFields, 'country_code')}
            handleChange={(newValue) => this.handleCountryChange(newValue)}
          />

          <TextInput
            title={'Landnaam'}
            name={'country_name'}
            value={formData.country_name}
            type='text'
            readonly={true}
          />

          <TextInput
            title={'Straat1'}
            name={'street1'}
            value={formData.street1}
            type='text'
            error={this.errorMessage(formErrorFields, 'street1')}
            handleChange={(newValue) => this.handleChange('street1', newValue)}
          />

          <TextInput
            title={'Straat2'}
            name={'street2'}
            value={formData.street2}
            type='text'
            handleChange={(newValue) => this.handleChange('street2', newValue)}
          />

          <TextInput
            title={'Postcode'}
            name={'postalcode'}
            value={formData.postalcode}
            type='text'
            error={this.errorMessage(formErrorFields, 'postalcode')}
            handleChange={(newValue) => this.handleChange('postalcode', newValue)}
          />

          <TextInput
            title={'Stad'}
            name={'city'}
            value={formData.city}
            type='text'
            error={this.errorMessage(formErrorFields, 'city')}
            handleChange={(newValue) => this.handleChange('city', newValue)}
          />

          <TextInput
            title={'Email'}
            name={'email'}
            value={formData.email}
            type='email'
            error={this.errorMessage(formErrorFields, 'email')}
            handleChange={(newValue) => this.handleChange('email', newValue)}
          />

          <br />
          <div className='form-group'>
            {loadingSubmit ? (
              <button className='btn btn-primary' disabled>
                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                <span className="visually-hidden" role="status">Loading...</span>
              </button>
            ) : (
              <input type="submit" className='btn btn-primary' value={submitLabel} />
            )}
            <button className="btn btn-link" onClick={() => this.props.prevForm()}>Terug</button>
          </div>
        </form>
      </div>
    )
  }
}

export default OrganisationForm