import Loader from "../Views/Loader"
import UpgradeLicenseForm from "./UpgradeLicenseForm"
import { Link } from "react-router-dom"
import { useState, useEffect, useCallback } from "react"
import { useNavigate } from 'react-router-dom'
import { submitError, errorToast, submitSuccess } from '../Helpers'
import axios from "axios"

export default function UpgradeLicense({ customerData }) {
  const [ isAuthenticated, setIsAuthenticated ] = useState(false)
  const [ orderSuccess, setOrderSuccess ] = useState(false)
  const [ orderData, setOrderData ] = useState({})
  const [ formErrorFields, setFormErrorFields ] = useState([])
  const [ licenseTypes, setLicenseTypes ] = useState([])
  const [ isLoading, setIsLoading ] = useState(true)

  const navigate = useNavigate()

  useEffect(() => {
    async function getLicenseTypes() {
      await axios.get(`${process.env.REACT_APP_API_URL}/api/licenses/types/greater/${customerData.organisation.license_type}`)
        .then(function (response) {
          if (response.data) {
            const licenseTypes = response.data.license_types
            setLicenseTypes(licenseTypes)
            setIsLoading(false)
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    if (JSON.stringify(customerData) === '{}') {
      navigate('/licenses/upgrade')
      errorToast('U moet eerst inloggen.')
    } else {
      setIsAuthenticated(true)

      if (licenseTypes.length === 0) {
        getLicenseTypes()
      }
    }
  }, [customerData, navigate, setIsAuthenticated, licenseTypes])

  const handleOrderSubmit = useCallback((data) => {
    const updatedOrderData = {
      org_uuid: customerData.organisation.uuid,
      account_uuid: customerData.account.uuid,
      ...data
    }

    setOrderData(updatedOrderData)

    axios.post(`${process.env.REACT_APP_API_URL}/api/shop/licenses/upgrade`, updatedOrderData)
      .then(function (response) {
        if (submitSuccess(response)) {
          setOrderData(response.data)
          setOrderSuccess(true)
        }
      })
      .catch(function (error) {
        console.error(error);
        const response = error.response.data
        if (submitError(response)) {
          errorToast(response.message)
          setFormErrorFields(response.fields)
        }
      });
  }, [customerData] )

  return (
    <>
      {isAuthenticated && (
        <>
          {orderSuccess ? (
            <>
              <h2 className='text-success'>Bestelling voltooid!</h2>
              <div className='rounded-3 p-2 border'>
                Beste {orderData.name},<br/><br/>

                MemoMove dankt u voor uw bestelling.<br/><br/>
                <p>
                  Je zal een email van ons ontvangen met een beschrijving van uw bestelling en deze dient u te beantwoorden (verificatie van correcte inzender).
                  Daarna zullen wij de licentie-voorwaarden voor uw organisatie aanpassen, en zullen wij ook een factuur meezenden. Je kan MemoMove beperkt gebruiken totdat de factuur betaald is,daarna kan je MemoMove gebruiken volgens de beschreven licentie-voorwaarden.
                </p>
              </div>

              {/* <Link to="/" className="btn btn-primary my-4">Terug naar de shop</Link> */}
              <Link to="https://memomove.eu" className="btn btn-primary my-4">Terug naar de website</Link>
            </>
          ) : (
            <>
              {isLoading ? (
                <Loader />
              ) : (
                <UpgradeLicenseForm
                  customerData={customerData}
                  licenseTypes={licenseTypes}
                  formErrorFields={formErrorFields}
                  handleOrderSubmit={handleOrderSubmit}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  )
}
