import React from 'react'
import { Link } from 'react-router-dom'
import SelectInput from '../Form/SelectInput'
import { periodeTypes, periodes } from '../Helpers'

class OrderLicenseForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      formData: {
        license_type: 'F',
        periode_type: 'Month',
        periode: '3',
        price: 0,
        credits: 0
      },
      formErrors: [],
      submitLabel: '',
      loadingSubmit: false
    }

    this.handleChange = this.handleChange.bind(this)
  }

  async componentDidMount () {
    // console.log(this.props.activeType);
    // console.log(this.props.licenseTypes);
    let formData
    if (this.props.activeType !== undefined) {
      const activeType = this.props.licenseTypes.find(type => type.name.toLowerCase() === this.props.activeType)
      if (!activeType) return

      const { periode_type, periode } = this.state.formData
      const { price, credits } = await this.props.calcLicense(activeType.value, periode_type, periode)
      formData = {
        ...this.state.formData,
        license_type: activeType.value,
        price,
        credits
      }
    } else if(JSON.stringify(this.props.orderData) !== '{}') {
      formData = {
        ...this.props.orderData
      }
    }
    
    if (formData) {
      this.setState({ formData: formData })
    }
  }

  errorMessage(errorFields, value) {
    return errorFields.find(error => error.field === value)
  }

  updateFormData(data) {
    this.setState({ formData: data })
  }

  handleSubmit(e) {
    e.preventDefault()

    const formData = {
      ...this.state.formData
    }

    this.setState({loadingSubmit: true}, () => {
      this.props.handleSubmit(formData)
      this.setState({loadingSubmit: false})
    })
  }

  async handleChange(fieldName, fieldValue) {
    let updatedFormData = { ...this.state.formData }
    updatedFormData[fieldName] = fieldValue

    let { license_type, periode_type, periode } = updatedFormData
    if (fieldName === 'periode_type') {
      if (fieldValue === 'Month' && periode < 3) {
        periode = 3
      } else if (fieldValue === 'Year' && periode > 5) {
        periode = 1
      }
    }

    const license = await this.props.calcLicense(license_type, periode_type, periode)
    updatedFormData['periode'] = periode
    updatedFormData['price'] = license.price
    updatedFormData['credits'] = license.credits
    
    this.updateFormData(updatedFormData)
  }

  render () {
    const { submitLabel, formErrorFields, licenseTypes } = this.props
    const { formData, loadingSubmit } = this.state
    const { license_type, price, credits } = formData

    return (
      <div className=''>
        <form className="" onSubmit={(e) => this.handleSubmit(e)}>
          <SelectInput
            title={'Licentie type'}
            name={'license_type'}
            value={license_type}
            options={licenseTypes}
            disabled={loadingSubmit}
            error={this.errorMessage(formErrorFields, 'license_type')}
            handleChange={(newValue) => this.handleChange('license_type', newValue)}
          />

          {license_type === 'F' ? (
            <div className='my-2'>
              <label htmlFor={''} className=''>Periode</label>
              <br/>
              <div className='rounded-2 border px-3 py-2 bg-warning-subtle'>
                21 dagen
              </div>
            </div>
          ) : (
            <>
              <SelectInput
                title={'Periode type'}
                name={'periode_type'}
                value={formData.periode_type}
                options={periodeTypes()}
                disabled={loadingSubmit}
                error={this.errorMessage(formErrorFields, 'periode_type')}
                handleChange={(newValue) => this.handleChange('periode_type', newValue)}
              />

              <SelectInput
                title={'Periode'}
                name={'periode'}
                value={formData.periode}
                options={periodes(this.state.formData.periode_type)}
                disabled={loadingSubmit}
                error={this.errorMessage(formErrorFields, 'periode')}
                handleChange={(newValue) => this.handleChange('periode', newValue)}
              />
            </>
          )}


          <br />

          {price !== '' && credits !== '' && (
            <div className='rounded-3 p-4 border bg-light-subtle'>
              <div className='d-flex items-center'>
                <div className='w-50'>
                  Totaal prijs:
                </div>
                <b className='w-50 text-end'>
                  &euro; {price}
                </b>
              </div>
              <div className='d-flex items-center'>
                <div className='w-50'>
                  Credits:
                </div>
                <b className='w-50 text-end'>
                  {credits}
                </b>
              </div>
            </div>
          )}

          <br />
          <div className='form-group'>
            {loadingSubmit ? (
              <button className='btn btn-primary' disabled>
                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                <span className="visually-hidden" role="status">Loading...</span>
              </button>
            ) : (
              <input type="submit" className='btn btn-primary' value={submitLabel} />
            )}
            {/* <Link to='/' className="btn btn-link">Annuleer</Link> */}
            <Link to="https://memomove.eu" className="btn btn-link">Annuleer</Link>
          </div>
        </form>
      </div>
    )
  }
}

export default OrderLicenseForm