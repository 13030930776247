import OrderCreditsForm from "./OrderCreditsForm"
import { useState, useEffect, useCallback } from "react"
import { Link, useNavigate } from 'react-router-dom'
import { submitError, errorToast, submitSuccess } from '../Helpers'
import axios from "axios"

export default function OrderCredits({ customerData }) {
  const [ isAuthenticated, setIsAuthenticated ] = useState(false)
  const [ orderSuccess, setOrderSuccess ] = useState(false)
  const [ orderData, setOrderData ] = useState({})
  const [ formErrorFields, setFormErrorFields ] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    if (JSON.stringify(customerData) === '{}') {
      navigate('/credits')
      errorToast('U moet eerst inloggen.')
    } else {
      setIsAuthenticated(true)
    }
  }, [customerData, navigate, setIsAuthenticated])

  const handleOrderSubmit = useCallback((data) => {
    const updatedOrderData = {
      org_uuid: customerData.organisation.uuid,
      account_uuid: customerData.account.uuid,
      ...data
    }

    setOrderData(updatedOrderData)
    axios.post(`${process.env.REACT_APP_API_URL}/api/shop/credits/order`, updatedOrderData)
      .then(function (response) {
        if (submitSuccess(response)) {
          setOrderData(response.data)
          setOrderSuccess(true)
        }
      })
      .catch(function (error) {
        const response = error.response.data
        if (submitError(response)) {
          errorToast(response.message)
          setFormErrorFields(response.fields)
        }
      });
  }, [customerData] )
  

  return (
    <>
    {isAuthenticated && (
      <>
        {orderSuccess ? (
          <>
            <h2 className='text-success'>Bestelling voltooid!</h2>
            <div className='rounded-3 p-2 border'>
              Beste {orderData.name},<br/><br/>

              MemoMove dankt u voor uw bestelling.<br/><br/>
              <p>
                Je zal een email van ons ontvangen met een beschrijving van uw bestelling en deze dient u te beantwoorden (verificatie van correcte inzender).
                Daarna zullen wij u onze factuur toesturen. Zodra de factuur betaald is zullen wij de credit-hoogte correct zetten.
              </p>
            </div>

            {/* <Link to="/" className="btn btn-primary my-4">Terug naar de shop</Link> */}
            <Link to="https://memomove.eu" className="btn btn-primary my-4">Terug naar de website</Link>
          </>
        ) : (
          <>
            <OrderCreditsForm
              customerData={customerData}
              formErrorFields={formErrorFields}
              handleOrderSubmit={handleOrderSubmit}
            />
          </>
        )}
      </>
    )}
    </>
  )
}
