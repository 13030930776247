import TextInput from "../Form/TextInput";
import SelectInput from '../Form/SelectInput'
import { useState, useCallback, useEffect } from "react";
import { periodeTypes, periodes } from '../Helpers'
import { Link } from "react-router-dom";
import axios from "axios";

export default function UpgradeLicenseForm({ customerData, licenseTypes, formErrorFields, handleOrderSubmit }) {
  const [ name, setName ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ licenseType, setLicenseType ] = useState('')
  const [ periodeType, setPeriodeType ] = useState('Month')
  const [ periode, setPeriode ] = useState('3')
  const [ price, setPrice ] = useState(0)
  const [ credits, setCredits ] = useState(0)
  const [ loadingSubmit, setLoadingSubmit ] = useState(false)

  const calcLicensePrice = useCallback(async (license_type, license_periode, periode) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/api/shop/licenses/${license_type}/${license_periode}/${periode}`)
      .then(function (response) {
        if (response.data) {
          setPrice(response.data.price)
          setCredits(response.data.credits)
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  },[])

  useEffect(() => {
    setName(customerData.account.name)
    setEmail(customerData.account.email)
    if (licenseType === '') {
      setLicenseType(licenseTypes[0].value)
    }

    if (price === 0) {
      calcLicensePrice(licenseTypes[0].value, periodeType, periode)
    }
  }, [setEmail, customerData, price, licenseType, calcLicensePrice, licenseTypes, periodeType, periode])

  const licenseAmount = price - customerData.organisation.discount

  const handleSubmit = useCallback((e) => {
    e.preventDefault()

    const formData = {
      name,
      email,
      license_type: licenseType,
      periode_type: periodeType,
      periode,
      price: licenseAmount
    }

    setLoadingSubmit(true)
    handleOrderSubmit(formData)
    setLoadingSubmit(false)
  }, [name, email, licenseType, periodeType, periode, setLoadingSubmit, licenseAmount, handleOrderSubmit])
  

  const handleChange = useCallback(async (fieldName, fieldValue) => {
    let newLicenseType = licenseType
    let newPeriodeType = periodeType
    let newPeriode = periode

    if (fieldName === 'name') {
      setName(fieldValue)
    } else if (fieldName === 'email') {
      setEmail(fieldValue)
    } else if (fieldName === 'newLicenseType') {
      newLicenseType = fieldValue
      setLicenseType(newLicenseType)
    } else if (fieldName === 'periodeType') {
      newPeriodeType = fieldValue
      setPeriodeType(newPeriodeType)

      if (fieldValue === 'Month' && periode < 3) {
        newPeriode = 3
      } else if (fieldValue === 'Year' && periode > 5) {
        newPeriode = 1
      }
      setPeriode(newPeriode)
    } else if (fieldName === 'periode') {
      newPeriode = fieldValue
      setPeriode(newPeriode)
    }

    await calcLicensePrice(newLicenseType, newPeriodeType, newPeriode)
  }, [setName, setEmail, setLicenseType, calcLicensePrice, licenseType, periodeType, periode])

  const errorMessage = useCallback((value) => {
    return formErrorFields.find(error => error.field === value)
  }, [formErrorFields])

  return (
    <>
      <form className="mb-4" onSubmit={(e) => handleSubmit(e)}>
        <TextInput
          title={'Naam'}
          name={'name'}
          value={name}
          type='text'
          error={errorMessage('name')}
          handleChange={(newValue) => handleChange('name', newValue)}
        />
        <TextInput
          title={'Email'}
          name={'email'}
          value={email}
          type='email'
          error={errorMessage('email')}
          handleChange={(newValue) => handleChange('email', newValue)}
        />

        <div>
          <p>
            Beste {customerData.account.firstname}, <br/><br/>
            Uw organisatie <b>{customerData.organisation.name}</b> heeft momenteel licentietype <b>{customerData.organisation.license_type_full.name}</b> en u heeft nog een licentie-bedrag-tegoed van <b>&euro; {customerData.organisation.discount}</b>.
          </p>
        </div>

        <SelectInput
          title={'Licentie type'}
          name={'license_type'}
          value={licenseType}
          options={licenseTypes}
          disabled={loadingSubmit}
          error={errorMessage('license_type')}
          handleChange={(newValue) => handleChange('newLicenseType', newValue)}
        />

        <SelectInput
          title={'Periode type'}
          name={'periode_type'}
          value={periodeType}
          options={periodeTypes()}
          disabled={loadingSubmit}
          error={errorMessage('periode_type')}
          handleChange={(newValue) => handleChange('periodeType', newValue)}
        />

        <SelectInput
          title={'Periode'}
          name={'periode'}
          value={periode}
          options={periodes(periodeType)}
          disabled={loadingSubmit}
          error={errorMessage(formErrorFields, 'periode')}
          handleChange={(newValue) => handleChange('periode', newValue)}
        />

        <div className="border rounded-3 p-2">
          <div className='d-flex items-center'>
            <div className='me-2'>
              Credits:
            </div>
            <b className=''>
              {credits}
            </b>
          </div>
          <div className='d-flex items-center'>
            <div className="me-2">
              Licentie bedrag:
            </div>
            <b>
              &euro; {price} - &euro; {customerData.organisation.discount} =
              <div className={`ms-1 d-inline${licenseAmount < 0 ? ' text-danger' : ''}`}>
                &euro; {licenseAmount}
              </div>
            </b>
          </div>
        </div>
        
        {licenseAmount < 0 && (
          <small className="d-block alert bg-warning mt-2">
            Het bedrag moet positief zijn om een bestelling te kunnen plaatsen.
          </small>
        )}

        <br />
        <div className='form-group'>
          {loadingSubmit ? (
            <button className='btn btn-primary' disabled>
              <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
              <span className="visually-hidden" role="status">Loading...</span>
            </button>
          ) : (
            <input type="submit" className='btn btn-primary' value={'Bestel'} disabled={licenseAmount < 0} />
          )}
          <Link to='/licenses/upgrade' className="btn btn-link">Annuleer</Link>
        </div>
      </form>
    </>
  )
}