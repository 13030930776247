import { useState, useEffect, useRef } from "react";
import { b2cPolicies, loginRequest } from "../authConfig";
import { MsalAuthenticationTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";

// MSAL imports
import { EventType, InteractionType, InteractionStatus, InteractionRequiredAuthError, ClientAuthError } from "@azure/msal-browser";

import Pages from './Pages'
import Loader from "./Views/Loader";
import { ErrorComponent } from "./Views/Error";

import {
  Routes,
  Route,
  useLocation
} from "react-router-dom"

export default function Authorizer() {
  const { inProgress, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const [ authorized, setAuthorized] = useState(false)
  // const [ requestError, setRequestError] = useState(null)
  // const [ status, setStatus ] = useState(null);
  // const [ navPage, setNavPage ] = useState('')
  const location = useLocation()

  let activeAccount = useRef(false)

  const signInRequest = {
    ...loginRequest
  }

  useEffect(() => {
    // console.log('Authorizer load: ', isAuthenticated);
    // console.log('location: ', location);

    const callbackId = instance.addEventCallback((event) => {
      // console.log(event);
      // console.log('pages msal-event: ', event);
      if ((event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) && event.payload.account) {
        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth 
         * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy 
         * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
         * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        // console.log('login success?: ', event);
        // if (event.payload.idTokenClaims['tfp'] === b2cPolicies.names.editProfile) {
        if (event.payload.idTokenClaims['tfp'] === b2cPolicies.names.signIn) {
          // retrieve the account from initial sing-in to the app
          // console.log('accounts: ', instance.getAllAccounts());
          const originalSignInAccount = instance.getAllAccounts()
              .find(account =>
                account.idTokenClaims.oid === event.payload.idTokenClaims.oid
                &&
                account.idTokenClaims.sub === event.payload.idTokenClaims.sub
                &&
                account.idTokenClaims['tfp'] === b2cPolicies.names.signIn
              );

          let signInFlowRequest = {
            authority: b2cPolicies.authorities.signIn.authority,
            account: originalSignInAccount
          };
  
          // silently login again with the signUpSignIn policy
          const acquireTokenSilent = instance.acquireTokenSilent(signInFlowRequest)
          acquireTokenSilent.then(function (response) {
            // call API
            return
          }).catch( function (error) {
              // call acquireTokenPopup in case of acquireTokenSilent failure
              // due to interaction required
              if (error instanceof InteractionRequiredAuthError || error instanceof ClientAuthError) {
                console.error(error);
                instance.acquireTokenPopup(signInFlowRequest).then(
                    function (response) {
                      // call API
                      // console.log(response);
                      return
                    }).catch(function (error) {
                      console.error(error);
                });
              }

              instance.clearCache()
              instance.loginRedirect(signInRequest)
          });
        }
        
        if (event.payload.idTokenClaims['tfp'] === b2cPolicies.names.signUp) {
          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = instance.getAllAccounts()
            .find(account =>
              account.idTokenClaims.oid === event.payload.idTokenClaims.oid
              &&
              account.idTokenClaims.sub === event.payload.idTokenClaims.sub
              &&
              account.idTokenClaims['tfp'] === b2cPolicies.names.signUp
            );
  
          let signUpFlowRequest = {
            authority: b2cPolicies.authorities.signUp.authority,
            account: originalSignInAccount
          };
  
          // silently login again with the signUpSignIn policy
          const acquireTokenSilent = instance.acquireTokenSilent(signUpFlowRequest)
          acquireTokenSilent.then(function (response) {
            // call API
            return
          }).catch( function (error) {
            // call acquireTokenPopup in case of acquireTokenSilent failure
            // due to interaction required
            if (error instanceof InteractionRequiredAuthError || error instanceof ClientAuthError) {
              // console.log(error);
              instance.acquireTokenPopup(signUpFlowRequest).then(function (response) {
                // console.log(response);
                return
                // call API
              }).catch(function (error) {
                  // console.log(error);
              });
            }
  
            instance.clearCache()
            instance.loginRedirect(signInRequest)
          });

        }
        
        // setAuthorized(isAuthenticated)
        // console.log(event.payload);

        if (event.payload.state === 'activate_account') {
          activeAccount.current = true
        }
      }


      if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        instance.clearCache()
        instance.loginRedirect(signInRequest)
      }

      // if (event.eventType === EventType.SSO_SILENT_SUCCESS && event.payload.account) {
      //   setStatus("ssoSilent success");
      //   console.log('ssoSilent success...');
      // }
      // console.log('status', status);

      // if (event.eventType === EventType.acquireTokenFailure) {}
    });
    
    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    }
        
  // eslint-disable-next-line  
  }, []);

  return (
    <Pages
      instance={instance}
      inProgress={inProgress}
      authorized={authorized}
      activeAccount={activeAccount}
    />
  )
}