import TextInput from './Form/TextInput'
import axios from 'axios'
// import Loader from './Views/Loader'
import { useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { submitError, errorToast, submitSuccess } from './Helpers'
import { useNavigate } from 'react-router-dom'

export default function CustomerLogin({ submitLabel, setCustomerData, redirectPath }) {
  const [ customerId, setCustomerId ] = useState('')
  const [ zipcode, setZipcode ] = useState('')
  const [ loadingSubmit, setLoadingSubmit ] = useState(false)
  const [ formErrorFields, setFormErrorFields ] = useState([])
  const navigate = useNavigate()

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    setLoadingSubmit(true)
    await axios.post(`${process.env.REACT_APP_API_URL}/api/shop/organisations`,
      {
        customerId,
        zipcode
      })
      .then(function (response) {
        if (submitSuccess(response)) {
          setCustomerData(response.data)
          navigate(redirectPath)
        }
      })
      .catch(function (error) {
        const response = error.response.data
        if (submitError(response)) {
          errorToast(response.message)
          setFormErrorFields(response.fields)
        }
      });
      setLoadingSubmit(false)

  }, [customerId, zipcode, setLoadingSubmit, setCustomerData, navigate, redirectPath])

  const handleChange = useCallback( (fieldName, fieldValue) => {
    if (fieldName === 'customerId') {
      setCustomerId(fieldValue)
    } else {
      setZipcode(fieldValue)
    }
  }, [setCustomerId, setZipcode])

  const errorMessage = useCallback((value) => {
    return formErrorFields.find(error => error.field === value)
  }, [formErrorFields])

  return (
    <>
      <form className="" onSubmit={(e) => handleSubmit(e)}>
        {errorMessage('form') && (
          <div className='alert alert-danger'>
            {errorMessage('form').message}
          </div>
        )}

        <TextInput
          title={'Klant Id'}
          name={'customer_id'}
          value={customerId}
          type='text'
          error={errorMessage('customerId')}
          handleChange={(newValue) => handleChange('customerId', newValue)}
        />

        <TextInput
          title={'Postcode'}
          name={'zipcode'}
          value={zipcode}
          type='number'
          error={errorMessage('zipcode')}
          handleChange={(newValue) => handleChange('zipcode', newValue)}
        />

        <br />
        <div className='form-group'>
          {loadingSubmit ? (
            <button className='btn btn-primary' disabled>
              <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
              <span className="visually-hidden" role="status">Loading...</span>
            </button>
          ) : (
            <input type="submit" className='btn btn-primary' value={submitLabel} />
          )}
          <Link to='/' className="btn btn-link">Annuleer</Link>
        </div>
      </form>
    </>
  )
}
