import { loginRequest, apiConfig } from "../authConfig";
import { msalInstance } from "../index";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

export default async function callApi(path = '', method = 'GET', payload = undefined, accessToken = undefined) {
    // console.log('calling api...');
    // console.log('accessToken: ', accessToken);
    if (!accessToken) {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }
    
        // const response = await msalInstance.acquireTokenSilent({
        const acquireTokenSilent = msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account
        })
        
        await acquireTokenSilent.then(function (response) {
            accessToken = response.accessToken;
        }).catch( function (error) {
            // call acquireTokenPopup in case of acquireTokenSilent failure
            // due to interaction required
            if (error instanceof InteractionRequiredAuthError) {
                // console.log(error);
            }
            
            msalInstance.clearCache()
            msalInstance.loginRedirect(loginRequest)
        });
    }

    if (accessToken) {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;

        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json")

        const options = {
            method,
            headers
        }

        if (payload) {
            options['body'] = JSON.stringify({ data: payload })
        }

        const uri = apiConfig.uri + path

        return fetch(uri, options)
            .then(async response => {
                if (response.ok) {
                    return {
                        status: response.status,
                        data: await response.json()
                    }
                }
                return Promise.reject(response);
            })
        // .catch(error => 
        //     console.log(error.json())
        // );
    }
}