import TextInput from "../Form/TextInput";
import { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";

export default function OrderCreditsForm({ customerData, formErrorFields, handleOrderSubmit }) {
  const [ name, setName ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ credits, setCredits ] = useState('')
  const [ loadingSubmit, setLoadingSubmit ] = useState(false)

  useEffect(() => {
    setName(customerData.account.name)
    setEmail(customerData.account.email)
  }, [setName, setEmail, customerData])

  const handleSubmit = useCallback((e) => {
    e.preventDefault()

    const formData = {
      name,
      email,
      credits
    }

    setLoadingSubmit(true)
    handleOrderSubmit(formData)
    setLoadingSubmit(false)
  }, [name, credits, email, setLoadingSubmit, handleOrderSubmit])
  

  const handleChange = useCallback( (fieldName, fieldValue) => {
    if (fieldName === 'name') {
      setName(fieldValue)
    } else if (fieldName === 'email') {
      setEmail(fieldValue)
    } else if (fieldName === 'credits') {
      setCredits(fieldValue)
    }
  }, [setEmail, setCredits])

  const errorMessage = useCallback((value) => {
    return formErrorFields.find(error => error.field === value)
  }, [formErrorFields])

  return (
    <>
      <form className="" onSubmit={(e) => handleSubmit(e)}>
        <TextInput
          title={'Naam'}
          name={'name'}
          value={name}
          type='text'
          error={errorMessage('name')}
          handleChange={(newValue) => handleChange('name', newValue)}
        />
        <TextInput
          title={'Email'}
          name={'email'}
          value={email}
          type='email'
          error={errorMessage('email')}
          handleChange={(newValue) => handleChange('email', newValue)}
        />
        <TextInput
          title={'Credits'}
          name={'credits'}
          value={credits}
          type='number'
          error={errorMessage('credits')}
          handleChange={(newValue) => handleChange('credits', newValue)}
        />

        <br />
        <div className='form-group'>
          {loadingSubmit ? (
            <button className='btn btn-primary' disabled>
              <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
              <span className="visually-hidden" role="status">Loading...</span>
            </button>
          ) : (
            <input type="submit" className='btn btn-primary' value={'Bestel'} />
          )}
          <Link to='/credits' className="btn btn-link">Annuleer</Link>
        </div>
      </form>
    </>
  )
}