import React  from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

export default function TextInput({ handleChange, title, name, value, type, readonly, focus, error, helper, step = 1}) {
  TextInput.propTypes = {
    handleChange: PropTypes.func,
    title: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
    readonly: PropTypes.bool,
    focus: PropTypes.bool,
    error: PropTypes.object,
    helper: PropTypes.string
  }
  
  TextInput.defaultProps = {
    // handleChange: (event) => console.info(`New value : ${event.target.value}`),
    handleChange: PropTypes.func,
    title: '',
    name: '',
    value: '',
    type: 'string',
    readonly: false,
    focus: false,
    error: {},
    helper: ''
  }

  let inputClassNames = 'form-control'
  if (readonly) {
    inputClassNames += ' text-bg-light'
  } else if (!_.isEmpty(error)) {
    inputClassNames += ' border-danger'
  }
  const labelClassNames = `d-flex ${_.isEmpty(error) ? '' : 'text-danger'}`

  return (
    <>
      {type === 'hidden' ? (
        <input type={type} value={value.toString()} id={name} name={name} className={inputClassNames} readOnly={readonly} autoFocus={focus} />
      ) : (
      <div className='my-2'>
          <label htmlFor={name} className={labelClassNames}>
            {title}
            {helper && (
              <small className='ms-auto text-danger-emphasis'>{helper}</small>
            )}
          </label>
          <div className="from-group">
            {type === 'number' ? (
              <input type={type} value={value} id={name} name={name} onChange={(event) => handleChange(event.target.value)} className={inputClassNames} readOnly={readonly} autoFocus={focus} step={step} min={1} />
            ) : (
              <input type={type} value={value} id={name} name={name} onChange={(event) => handleChange(event.target.value)} className={inputClassNames} readOnly={readonly} autoFocus={focus} />
            )}
            {!_.isEmpty(error) && (
              <small className='text-danger'>
                {error.message}
              </small>
            )}
          </div>
        </div>
      )}
    </>
  )
}
