import Home from './Home'
import OrderLicense from './License/OrderLicense'
import Credits from './Credits/Credits'
import UpgradePage from './License/UpgradePage'

// import { useState, useEffect, useCallback } from "react";
import {
  Routes,
  Route,
  Link
} from 'react-router-dom'

export default function Pages({ instance, inProgress, authorized, activeAccount, isAuthenticated }) {  
  return (
    <>
      <header className="container d-flex justify-content-between my-2">
        <Link to="/" className="d-flex align-items-center mb-2 mb-lg-0 text-decoration-none">
          <img src='/static/img/logo_website.png' alt='Logo Memomove' className='me-2' />
          MemoMove - Shop
        </Link>
        {/* <div className='d-flex align-items-center'>
          <Link to="/licenses/order" className='me-2'>Order License</Link>
          <Link to="/licenses/upgrade" className='me-2'>Upgrade License</Link>
          <Link to="/credits">Order Credits</Link>
        </div> */}

        {/* <Link to="https://memomove.eu" className='btn btn-outline-secondary'>Website</Link> */}
      </header>
      <div className='container my-5'>
        <Routes>
          <Route index="/" path="/" element={
            <Home />
          } />
          <Route path="licenses/order/:licenseType?" element={
            <OrderLicense
              instance={instance}
              // inProgress={inProgress}
              authorized={authorized}
              activeAccount={activeAccount}
            />
          } />
          <Route path="licenses/upgrade/*" element={
            <UpgradePage />
          } />
          <Route path="credits/*" element={
            <Credits />
          } />
        </Routes>
      </div>
    </>
  )
}